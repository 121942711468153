import Image from "react-bootstrap/Image";
import styled from "styled-components";

const Logo = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;

export const PageError = () => (
  <div className="d-flex justify-content-center vw-100 vh-100 align-items-center">
    <div>
      <Logo src="checkout/logo.png" />
      <p className="mt-4">Página não encontrada.</p>
    </div>
  </div>
);

export default PageError;
